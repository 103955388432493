
import { httpGet, httpPost } from '@/api';
import FileContent from '@/components/FileContent/FileContent.vue';
import { ajaxLoading, errorMessage, setTitleLabel, successMessage } from '@/utils/publicMethods';
import { defineComponent, computed } from 'vue';
import useFormData from './useAgentFormData';
export default defineComponent({
  components: { FileContent },
  props: {
    /** 新增编辑表单状态 1新增 2编辑 */
    formType: {
      type: [Number],
      default: 1,
    },
    data: {
      type: [Object],
    },
  },
  emits: ['close'],
  setup(props, ctx) {
    const params: any = {};
    if (props.formType === 2) {
      // params = Object.assign({ operateType: 2 }, props.data);
      // params.isInPool = params.isInPool ? 1 : 0;
      // let data = props.data as any;
      // 设置区域
      // addressData.value = [data?.provinceId, data.cityId, data.areaId];
    }
    const { rules, formData, formRef, setFormData, contractUrlsArray, changeShortName, changeChinese, changeTel, fileTips } = useFormData(params);
    /** 获取文件信息 */
    const getFileUrls = (fileList: any[]) => {
      formData.agencyFileList = [];
      fileList.forEach((item) => {
        formData.agencyFileList.push({
          storageFileUrl: item.url,
          storageFileName: item.name,
        });
      });
    };
    const readOnly = computed(() => {
      return props.formType > 2;
    });

    const close = (status = false) => {
      ctx.emit('close', status);
    };
    const initFile = () => {
      contractUrlsArray.value = [];
      const { agencyFileList } = formData;
      agencyFileList.forEach((item) => {
        item.storageFileUrl = item.url;
        item.storageFileName = item.name;
        (contractUrlsArray.value as any).push({
          name: item.name,
          url: item.url,
        });
      });
    };
    const submitData = async () => {
      const params = {
        ...formData,
      };
      ajaxLoading.lock();
      const res = await httpPost('/malicrm/agency/operateAgency', params);
      if (res.code === 200) {
        ajaxLoading.unLock();
        successMessage('保存成功');
        close(true);
      } else {
        ajaxLoading.unLock();
        errorMessage(res.message);
      }
    };
    /** 提交表单 */
    const saveFormData = async () => {
      formRef.value.validate((valid) => {
        if (valid) {
          // formData.signDate = formatTime(formData.signDate, 'YYYY-MM-DD');
          submitData();
        } else {
          return false;
        }
      });
    };
    // 初始化数据
    const initData = async (id) => {
      if (!id) return;
      const res = await httpGet(`/malicrm/agency/agencyDetail/${id}`);
      if (res.code === 200) {
        setFormData(res.result);
        initFile();
      } else {
        errorMessage(res.message);
      }
    };
    if (props.formType > 1) {
      // const feeObj = JSON.parse(JSON.stringify(props.data));
      // setFormData(feeObj.value);
      // formRef.value.validateField('settleType');
      // let data = (props.data as any).value;
      initData((props.data as any).id);
    }
    return {
      close,
      formData,
      saveFormData,
      setFormData,
      rules,
      formRef,
      setTitleLabel,
      getFileUrls,
      contractUrlsArray,
      initFile,
      initData,
      readOnly,
      changeShortName,
      changeChinese,
      changeTel,
      fileTips,
    };
  },
});
