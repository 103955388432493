import { reactive, ref, toRefs } from 'vue';
type FormData = {
  email: string;
  fullName: string;
  id: number | string;
  name: string;
  position: string;
  remark: string;
  sex: number;
  shortName: string;
  telephone: string;
  version: number | string;
  agencyFileList: any[];
  weixin: string;
  [propName: string]: any;
};
export default (params) => {
  const formRef = ref(); // 表单校验对象使用
  const formData: FormData = reactive({
    email: '',
    fullName: '',
    id: null,
    name: '',
    position: '',
    remark: '',
    sex: 1,
    shortName: '',
    telephone: '',
    version: null,
    agencyFileList: [],
    weixin: '',
    ...params,
  });
  const customFormData = reactive({
    contractUrlsArray: [], // 框架合同文件
    fileType: 'crmAgentContract',
    fileTips: '允许类型：pdf,doc,docx,jpg,png,gif,bmp,zip   大小上限：30M   只支持上传5个文件',
  });
  const rules = reactive({
    shortName: [{ required: true, message: '请输入代理', trigger: 'blur' }],
    fullName: [{ required: true, message: '请输入代理全称', trigger: 'blur' }],
  });
  const setFormData = (paramsData: any) => {
    for (const key in formData) {
      if (Object.prototype.hasOwnProperty.call(paramsData, key)) {
        formData[key] = paramsData[key];
      }
    }
  };
  const changeShortName = (e, name) => {
    let value = e.target.value;
    value = value.replace(/[^a-zA-Z\u4E00-\u9FA5\d]/g, ''); // 清除中文以外的字符
    formData[name] = value;
  };
  const changeChinese = (e, name) => {
    let value = e.target.value;
    value = value.replace(/[^\u4E00-\u9FA5]/g, ''); // 清除中文以外的字符
    formData[name] = value;
  };
  const changeTel = (e, name) => {
    let value = e.target.value;
    value = value.replace(/[^\d-]/g, ''); // 清除中文以外的字符
    formData[name] = value;
  };
  return {
    rules,
    formData,
    formRef,
    setFormData,
    ...toRefs(customFormData),
    changeShortName,
    changeChinese,
    changeTel,
  };
};
